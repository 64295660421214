import React from 'react';
import {object} from 'prop-types';

import DataGuard from '../../commons/components/data/DataGuard.js';
import {cancellable} from '../../commons/utils/PromiseUtils.js';
import loadPdfJS from '../../external/loadPdfJS.js';
import SynFormattedMessage from '../../i18n/components/SynFormattedMessage.js';
import PdfViewerMessagesTranslator from '../../i18n/translators/PdfViewerMessagesTranslator.js';
import {getNumberOfPages} from '../utils/PDFUtils.js';
import ViewerError from '../ViewerError.js';

export default class PdfLoader extends React.Component {
	constructor(props, context) {
		super(props, context);

		this.boundCaptureError = this.captureError.bind(this);
		this.boundCapturePdfJsModule = this.capturePdfJsModule.bind(this);
		this.boundCaptureDocument = this.capturePdfDocument.bind(this);
		this.boundCapturePages = this.capturePages.bind(this);

		this.state = {
			promisedDocument: null,
			promisedPages: null,
			pages: null,
			pdfDocument: null,
			pdfjs: null,
			error: null
		};

		loadPdfJS()
			.then(this.boundCapturePdfJsModule)
			.catch(this.boundCaptureError);
	}

	render() {
		const {error, pages, pdfDocument} = this.state;
		const {children} = this.props;
		if (error) {
			throw new ViewerError('Failed to load pdf for display', error,
				<SynFormattedMessage message='FailedToLoadPdf' translator={PdfViewerMessagesTranslator} />
			);
		}
		return (
			<DataGuard data={Boolean(pages) && Boolean(pdfDocument)}>
				{React.cloneElement(React.Children.only(children), {pages, pdfDocument})}
			</DataGuard>
		);
	}

	shouldComponentUpdate(nextProps, nextState) {
		const {pdfFile, children} = this.props;
		const {pdfFile: nextPdfFile, children: nextChildren} = nextProps;
		const relevantPropsChanged = pdfFile !== nextPdfFile || children !== nextChildren;

		const {pages, error, pdfjs} = this.state;
		const {pages: nextPages, error: nextError, pdfjs: nextPdfJs} = nextState;
		const relevantStateChanged = pages !== nextPages || error !== nextError || pdfjs !== nextPdfJs;

		return relevantStateChanged || relevantPropsChanged;
	}

	componentDidMount() {
		this.loadPdfDocument();
	}

	componentDidUpdate(prevProps, prevState) {
		const {pdfFile} = this.props;
		const {pdfFile: prevPdfFile} = prevProps;
		const {pdfjs} = this.state;
		const {pdfjs: prevPdfjs} = prevState;
		if (pdfFile !== prevPdfFile || pdfjs !== prevPdfjs) {
			this.loadPdfDocument();
		}
	}

	componentWillUnmount() {
		this.cancelLoadPages();
		this.cancelLoadPdfDocument();
	}

	captureError(error) {
		this.setState({promisedDocument: null, error});
	}

	capturePdfJsModule(pdfjs) {
		this.setState({pdfjs});
		this.loadPdfDocument();
	}

	capturePdfDocument(pdfDocument) {
		this.setState({promisedDocument: null, pdfDocument});
		this.loadPages(pdfDocument);
	}

	loadPages(pdfDocument) {
		this.cancelLoadPages();
		if (pdfDocument) {
			const numberOfPages = getNumberOfPages(pdfDocument);
			const pagePromises = new Array(numberOfPages);
			for (let pageIndex = 0; pageIndex < numberOfPages; ++pageIndex) {
				pagePromises[pageIndex] = pdfDocument.getPage(pageIndex + 1);
			}
			const promisedPages = cancellable(Promise.all(pagePromises))
				.maybe(this.boundCapturePages)
				.catch(this.boundCaptureError);
			this.setState({promisedPages, pages: null});
		}
	}

	capturePages(allPages) {
		this.setState({promisedPages: null, pages: allPages});
	}

	cancelLoadPages() {
		this.setState(prevState => {
			const {promisedPages} = prevState;
			if (promisedPages) {
				promisedPages.cancel();
			}
			return {...prevState, promisedPages: null};
		});
	}

	loadPdfDocument() {
		this.cancelLoadPdfDocument();
		this.setState(prevState => {
			const {pdfjs} = prevState;
			let {promisedDocument} = prevState;
			const {pdfFile} = this.props;
			if (Boolean(pdfFile) && Boolean(pdfjs)) {
				const documentLoader = pdfjs.getDocument({
				   data: pdfFile.slice(0),
				   isEvalSupported: false
				});
				promisedDocument = cancellable(documentLoader.promise)
					.cancelled(documentLoader.destroy.bind(documentLoader))
					.maybe(this.boundCaptureDocument)
					.catch(this.boundCaptureError);
			}
			return {...prevState, promisedDocument};
		});
	}

	cancelLoadPdfDocument() {
		this.setState(prevState => {
			const {promisedDocument} = prevState;
			if (promisedDocument) {
				promisedDocument.cancel();
			}
			return {...prevState, promisedDocument: null};
		});
	}
}

PdfLoader.propTypes = {
	pdfFile: object
};
