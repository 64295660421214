import Immutable from 'immutable';

import {identity, memoizeLast} from './FunctionUtils.js';
import {ensureArray} from './SeqUtils';

export default class DataTree {
	#getMemoizedKeySeq;

	constructor(accountant = identity) {
		this.data = Immutable.OrderedMap();
		this.accountant = accountant;
		this.accountingData = accountant(undefined, undefined, this);
		this.#getMemoizedKeySeq = memoizeLast(getKeySequence);
	}

	isEmpty() {
		return this.data.isEmpty();
	}

	addOrReplace(path, data) {
		return walk(path,
			entryName => this.updateData(entryName, () => data),
			(entryName, remainingPath) => this.updateEntries(entryName, existingEntries => {
				const entries = existingEntries || new DataTree(this.accountant);
				return entries.addOrReplace(remainingPath, data);
			})
		);
	}

	removeEntry(path) {
		return walk(path,
			entryName => this.updateEntry(entryName, () => undefined),
			(entryName, remainingPath) => this.updateEntries(entryName, entries => {
				if (entries) {
					return entries.removeEntry(remainingPath);
				}
				return undefined;
			})
		);
	}

	removeAllEntries() {
		const newAccountingData = this.accountant(this.accountingData, this, undefined);
		return this.newOrThis(Immutable.OrderedMap(), newAccountingData);
	}

	updateEntry(entryName, updater) {
		let newAccountantData = this.accountingData;
		const currentEntry = this.data.get(entryName);
		const newEntry = updater(currentEntry);
		if (currentEntry !== newEntry) {
			newAccountantData = [getEntryValue, getEntries].reduce(
				(acc, getter) => {
					const oldValue = getter(currentEntry);
					const newValue = getter(newEntry);
					if (oldValue !== newValue) {
						return this.accountant(acc, getter(currentEntry), getter(newEntry));
					}
					return acc;
				},
				this.accountingData
			);
		}
		const entryIsEmpty = newEntry === undefined || (
			getEntryValue(newEntry) === undefined &&
			(getEntries(newEntry) || Immutable.Map()).isEmpty()
		);
		const newData = entryIsEmpty ? this.data.remove(entryName) : this.data.set(entryName, newEntry);
		return this.newOrThis(newData, newAccountantData);
	}

	updateData(entryName, updater) {
		return this.updateEntry(entryName, entry => {
			const currentValue = getEntryValue(entry);
			const newValue = updater(currentValue);
			if (newValue === undefined) {
				return entry ? clearEntryValue(entry) : undefined;
			}
			return entry ? setEntryValue(entry, newValue) : newLeaf(newValue);
		});
	}

	updateEntries(entryName, updater) {
		return this.updateEntry(entryName, entry => {
			const currentEntries = getEntries(entry);
			const newEntries = updater(currentEntries);
			if (newEntries === undefined) {
				return entry ? clearEntries(entry) : undefined;
			}
			return entry ? setEntries(entry, newEntries) : newNode(newEntries);
		});
	}

	move(from, to) {
		const entry = this.getValue(from);
		return this
			.removeEntry(from)
			.addOrReplace(to, entry);
	}

	listEntries(path) {
		const thisPath = ensureArray(path);
		const [thisEntry, ...remainingPath] = thisPath;
		if (thisEntry === undefined) {
			return this.#getMemoizedKeySeq(this.data);
		}
		const subTree = getEntries(this.data.get(thisEntry, null));
		if (subTree) {
			return subTree.listEntries(remainingPath);
		}
		return Immutable.List();
	}

	hasEntry(path) {
		return walk(path,
			entryName => (entryName ? this.data.has(entryName) : false),
			(entryName, remainingPath) => {
				const subTree = getEntries(this.data.get(entryName, null));
				if (subTree) {
					return subTree.hasEntry(remainingPath);
				}
				return false;
			}
		);
	}

	getValue(path) {
		return walk(path,
			entryName => (entryName ? getEntryValue(this.data.get(entryName)) : undefined),
			(entryName, remainingPath) => {
				const subTree = getEntries(this.data.get(entryName, null));
				if (subTree) {
					return subTree.getValue(remainingPath);
				}
				return undefined;
			}
		);
	}

	getSubTree(path) {
		return walk(path,
			entryName => (entryName ? getEntries(this.data.get(entryName)) : undefined),
			(entryName, remainingPath) => {
				const subTree = getEntries(this.data.get(entryName, null));
				if (subTree) {
					return subTree.getSubTree(remainingPath);
				}
				return undefined;
			}
		);
	}

	newOrThis(newData, newAccountantData = this.accountingData) {
		if (this.data !== newData || this.accountingData !== newAccountantData) {
			return createDerivedDataTree(this.accountant, newData, newAccountantData);
		}
		return this;
	}

	getAccountingData() {
		return this.accountingData;
	}
}

function validatePath(path) {
	if (!path || Array.isArray(path) && path.length === 0) {
		throw new Error(`Invalid path: ${path}`);
	}
}

function ensureValidArray(path) {
	validatePath(path);
	return ensureArray(path);
}

function walk(path, entryVisitor, treeVisitor) {
	const thisPath = ensureValidArray(path);
	const [thisName, ...remainingPath] = thisPath;
	if (remainingPath.length === 0) {
		return entryVisitor(thisName);
	}
	return treeVisitor(thisName, remainingPath);
}

function createDerivedDataTree(accountant, data, accountingData) {
	const newTree = new DataTree(accountant);
	newTree.data = data;
	newTree.accountingData = accountingData;
	return newTree;
}

function newLeaf(value) {
	return Immutable.Map().set('value', value);
}

function newNode(entries) {
	return Immutable.Map().set('entries', entries);
}

function setEntryValue(entry, value) {
	return entry.set('value', value);
}

function getEntryValue(entry) {
	return entry ? entry.get('value') : undefined;
}

function clearEntryValue(entry) {
	return entry ? entry.remove('value') : undefined;
}

function setEntries(entry, entries) {
	return entry.set('entries', entries);
}

function getEntries(entry) {
	return entry ? entry.get('entries') : undefined;
}

function clearEntries(entry) {
	return entry ? entry.remove('entries') : undefined;
}

function getKeySequence(map) {
	return map.keySeq();
}
